import React, { useEffect, useState } from "react";
import "./App.css";
import Logo from "./logo.png";

function App() {
  const [count, setCount] = useState(0);
  const [dotAnimation, setDotAnimation] = useState("");

  useEffect(() => {
    const intId = setInterval(() => {
      let dt = `${dotAnimation}.`;
      if (count === 3) {
        dt = "";
        setCount(0);
      } else {
        setCount(count + 1);
      }
      setDotAnimation(dt);
    }, 500);
    return () => {
      clearInterval(intId);
    };
  }, [count, dotAnimation]);

  return (
    <div className="App">
      <section className="landing">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-center">
            <img className="inline-block" src={Logo} alt="spooky_logo" />
          </div>
          {/* <div className="text-center">
            Collection available Now!
            <div>
              on <a
                rel="noreferrer"
                target="_blank"
                className="underline"
                href="https://opensea.io/collection/spooky-planet"
              >
              opensea.io
              </a>
            </div>
          </div> */}
          <div
            className="text-left mt-10 mx-auto"
            style={{
              width: "230px",
            }}
          >
            <h3>Coming soon{dotAnimation}</h3>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
